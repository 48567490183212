<template>
    <div class="background-line"></div>
</template>

<style>
* {
    box-sizing: border-box;
    margin: 0px;
}

body {
    background-color: #1f1f1f;
    overflow-x: hidden;
}

.background-line {
    position: absolute;
    width: 10000px;
    height: 1000px;
    background-color: #9331ff;
    transform: rotate(175deg);
    top: -1050px;
    left: -1000px;
    animation: BackgroundAnimation 15s infinite ;
}

@keyframes BackgroundAnimation {
    0% {
        transform: rotate(175deg);
    }


    50% {
        transform: rotate(177deg);
        top: -900px
    }

    100% {
        transform: rotate(175deg);
    }
}
</style>