<template>
  <WebsiteBackground />
  <div class="container">
    <ContentHeader />
    <ProfileLinks />
  </div>
</template>

<script>
import WebsiteBackground from './components/WebsiteBackground.vue'
import ContentHeader from './components/ContentHeader.vue';
import ProfileLinks from './components/ProfileLinks.vue';

export default {
  name: 'App',
  components: {
    WebsiteBackground,
    ContentHeader,
    ProfileLinks
  }
}
</script>


<style>
.container {
    width: 1000px;
    height: 500px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width: 1100px) {
  .container {
    width: calc(100vw - 100px);
    height: auto;
  }
}
</style>