<template>
    <div class="profile-links-container">
        <div class="profile-link-box" @click="gotoSite" data-site="https://discord.com/users/872074851932856330">
            <div class="profile-box-link-icon-container">
                <i class='bx bxl-discord-alt profile-box-icon'></i>
            </div>
            <div class="profile-box-media-name">Discord</div>
            <div class="profile-box-media-content">wikomiks</div>
            <i class='bx bx-right-arrow-alt'></i>
        </div>
        <div class="profile-link-box" @click="gotoSite" data-site="https://github.com/wikomiks">
            <div class="profile-box-link-icon-container github">
                <i class='bx bxl-github profile-box-icon'></i>
            </div>
            <div class="profile-box-media-name">Github</div>
            <div class="profile-box-media-content">wikomiks</div>
            <i class='bx bx-right-arrow-alt'></i>
        </div>
        <div class="profile-link-box" @click="gotoSite" data-site="https://t.me/wikomiks">
            <div class="profile-box-link-icon-container telegram">
                <i class='bx bxl-telegram profile-box-icon'></i>
            </div>
            <div class="profile-box-media-name">Telegram</div>
            <div class="profile-box-media-content">wikomiks</div>
            <i class='bx bx-right-arrow-alt'></i>
        </div>
        <div class="profile-link-box" @click="gotoSite" data-site="https://www.youtube.com/@wikomiks">
            <div class="profile-box-link-icon-container youtube">
                <i class='bx bxl-youtube profile-box-icon'></i>
            </div>
            <div class="profile-box-media-name">YouTube</div>
            <div class="profile-box-media-content">wikomiks</div>
            <i class='bx bx-right-arrow-alt'></i>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        gotoSite(event) {
            const site = event.currentTarget.dataset.site;
            window.open(site)
        }
    }
}


</script>

<style>
.profile-links-container {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.profile-link-box {
    width: 450px;
    height: 70px;
    position: relative;
    border-radius: 4px;
    background-color: rgb(37, 37, 37);
    cursor: pointer;
}

.profile-link-box:hover .profile-box-link-icon-container {
    margin-top: -5px;
}

.profile-link-box:hover .bx-right-arrow-alt {
    right: 30px;
    color: rgb(208, 208, 208);
}

.profile-box-link-icon-container {
    width: 55px;
    height: 55px;
    background: linear-gradient(45deg, rgb(31, 102, 255), rgb(106, 0, 255));
    position: absolute;
    left: -10px;
    top: 7.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    transition: all .3s ease-in-out;
}

.github {
    background: linear-gradient(45deg, rgb(8, 156, 255),rgb(255, 103, 2));
    color: black;
}

.telegram {
    background: linear-gradient(45deg, rgb(31, 132, 255), rgb(0, 225, 255));
}

.youtube {
    background: linear-gradient(45deg, rgb(255, 50, 31), rgb(255, 0, 0));
}

.profile-box-icon {
    font-size: 30px;
    color: white;
}

.profile-box-media-name {
    margin: 15px 0px 0px 90px;
    color: white;
    font-family: Graphik,sans-serif;
    font-size: 19px;
}

.profile-box-media-content {
    margin: 5px 0px 0px 90px;
    color: rgb(116, 116, 116);
    font-family: sans-serif;
    font-size: 14px;
}

.bx-right-arrow-alt {
    color: white;
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 22px;
    transition: all .3s;
}

@media screen and (max-width: 1000px) {
    .profile-links-container {
        height: auto;
    }

    .profile-link-box {
        margin-top: 5px;
    }
}


@media screen and (max-width: 600px) {
    .profile-link-box {
        width: calc(100vw - 160px)
    }
}

@media screen and (max-width: 500px) {
    .profile-link-box {
        .profile-box-media-name, .profile-box-media-content {
            margin-left: 70px;
        }
    }

    .bx-right-arrow-alt {
        right: 20px;
    }
}

@media screen and (max-width: 400px) {
    .profile-box-link-icon-container {
        width: 40px;
        height: 40px;
        top: 15px;
    }

    .profile-link-box {
        .profile-box-media-name, .profile-box-media-content {
            margin-left: 50px;
        }
    }

    .profile-box-media-name {
        font-size: 16px;
    }

    .profile-box-media-content {
        font-size: 12px;
    }
}
</style>