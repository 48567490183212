<template>
    <div class="container-header">
        <div class="container-header-avatar-box">
            <img src="../images/avatar.png" alt="avatar">
        </div>
        <div class="nickname-content">wikomiks</div>
        <div class="country"><i class='bx bx-map'></i> Gdańsk, Poland</div>
        <div class="description-content">Self-taught software developer.</div>    
    </div>
</template>

<style>
.container-header {
    width: 100%;
    height: 250px;
    background-color: #202020;
    border-radius: 6px;
}


.container-header-avatar-box {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
}


.container-header-avatar-box > img {
    border-radius: 50%;
    width: 200px;
    -webkit-box-shadow: 0px 29px 24px -14px rgb(19, 19, 19);
    -moz-box-shadow: 0px 29px 24px -14px rgb(19, 19, 19);
    box-shadow: 0px 29px 24px -14px rgb(19, 19, 19);
}



.nickname-content {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 130px;
    color: white;
    font-family: sans-serif;
    font-size: 28px;
}


.country {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 115px;
    color: rgb(183, 183, 183);
    font-family: sans-serif;
    font-size: 14px;
}



.description-content {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 95px;
    color: white;
    font-family: sans-serif;
    font-size: 14px;
}

.bx-map {
    margin-right: 5px;
}
</style>
